@charset "UTF-8";

$component: component-class("table1");

// [class*="#{$component}--"] {
// }

.#{$component}--layout1 {
  table {
    margin-bottom: 0;

    tr {
      &:last-child {

        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}
