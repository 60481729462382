@charset "UTF-8";

$component: component-class("fx");

.#{$component} {

  &--grad-gray1-1 {
    position: relative;

    &:before {
      content: " ";
      display: block;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      // height: 20px;
      height: 32px;

      background: linear-gradient(180deg,
          rgba(token-color("set1", "black1"), 0.05) 0%,
          rgba(token-color("set1", "black1"), 0) 100%);
    }
  }

  &--grad-violet1-1 {
    &:before {
      content: " ";
      display: block;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;


      background: linear-gradient(90deg,
          rgba(token-color("set1", "violet1"), 1) 0%,
          rgba(token-color("set1", "violet1"), 0) 100%);

      @include media-breakpoint-up(md) {
        width: 55%;
        min-width: 400px;
      }
    }
  }

  &--grad-pink1violet1-135-1 {
    background: rgba(token-color("set1", "pink1"), 1);
    background: linear-gradient(135deg, rgba(token-color("set1", "pink1"), 1) 0%, rgba(token-color("set1", "violet1"), 1) 100%);
  }
}
