@charset "UTF-8";

$component: component-class("form");

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

@mixin c-form-text-like-field-rules-theme-dark1() {
  background-color: rgba(#FFF, 0.5);
  color: #FFF;

  @include placeholder() {
    color: #FFF;
  }
}

.#{helper-class("theme")}--dark1 .#{$component} {

  .#{$component}__field--text {
    .form-control {
      @include c-form-text-like-field-rules-theme-dark1();
    }
  }

  .#{$component}__field--textarea {
    .form-control {
      @include c-form-text-like-field-rules-theme-dark1();
    }
  }

  .#{$component}__field--select {
    .form-select {
      @include c-form-text-like-field-rules-theme-dark1();
    }
  }

  .#{$component}__field--select {
    .form-select {
      border: 0;
    }
  }
}
