@charset "UTF-8";

$component: component-class("indi1");

.#{$component} {
  p {
    @include media-breakpoint-down(md) {
      max-width: 232px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
