@charset "UTF-8";

//////////////////////////////////////////////////
// UTILS
//////////////////////////////////////////////////

@function component-class($component-class) {
  @return $zaux-component-prefix +$component-class;
}

@function helper-class($helper-class) {
  @return $zaux-helper-prefix +$helper-class;
}

@function parse-int($n) {
  @return $n / ($n * 0 + 1);
}

@function has-legacy-layer() {
  @return token-def("legacy-layer");
}

@function rem($size, $base: $zaux-font-size-root-px) {
  @return ($size / $base) * 1rem;
}

@function em($size, $base: $zaux-font-size-root-px) {
  @return ($size / $base) * 1em;
}

@function url-friendly-color($hex) {
  @return "%23"+str-slice("#{$hex}", 2, -1);
}

@function clear-keys-from-map($map) {
  $map: map-remove($map, 'generateSassMap');
  @return $map;
}


//////////////////////////////////////////////////
// TOKENS - DEF
//////////////////////////////////////////////////

@function token-def($key) {
  @return map-get($zaux-token-defs, $key);
}


//////////////////////////////////////////////////
// TOKENS - COLORS
//////////////////////////////////////////////////

@function token-colors() {
  @return clear-keys-from-map(map-get($zaux-token-colors, 'values'));
}

@function token-color-set($setName) {
  @return map-get(token-colors(), $setName);
}

@function token-color($setName, $colorName) {
  @return map-get(token-color-set($setName), $colorName);
}


//////////////////////////////////////////////////
// TOKENS - STYLES
//////////////////////////////////////////////////

@function token-style($key) {
  @return map-get(clear-keys-from-map($zaux-token-styles), $key);
}


//////////////////////////////////////////////////
// TOKENS - CONTAINER
//////////////////////////////////////////////////

@function token-container($val) {
  @return map-get(clear-keys-from-map($zaux-token-containers), $val);
}

@function token-container-values() {
  @return token-container('values');
}

@function token-container-val($key) {
  @return map-get(token-container-values(), $key);
}


//////////////////////////////////////////////////
// TOKENS - FONTSIZE
//////////////////////////////////////////////////

@function token-fontsize($val) {
  @return map-get(clear-keys-from-map($zaux-token-fontsizes), $val);
}

@function token-fontsize-values() {
  @return token-fontsize('values');
}

@function token-fontsize-val($key) {
  @return map-get(token-fontsize-values(), $key);
}


//////////////////////////////////////////////////
// TOKENS - LINEHEIGHT
//////////////////////////////////////////////////

@function token-lineheight($val) {
  @return map-get(clear-keys-from-map($zaux-token-lineheights), $val);
}

@function token-lineheight-values() {
  @return token-lineheight('values');
}

@function token-lineheight-val($key) {
  @return map-get(token-lineheight-values(), $key);
}


//////////////////////////////////////////////////
// TOKENS - SIZE
//////////////////////////////////////////////////

@function token-size($val) {
  @return map-get(clear-keys-from-map($zaux-token-sizes), $val);
}

@function token-size-values() {
  @return token-size('values');
}

@function token-size-val($val) {
  @return map-get(token-size-values(), $val);
}


//////////////////////////////////////////////////
// TOKENS - GUTTERS
//////////////////////////////////////////////////

@function token-gutter($val) {
  @return map-get(clear-keys-from-map($zaux-token-gutters), $val);
}

@function token-gutter-values() {
  @return token-gutter('values');
}

@function token-gutter-val($val) {
  @return map-get(token-gutter-values(), $val);
}


//////////////////////////////////////////////////
// TOKENS - BREAKPOINTS
//////////////////////////////////////////////////

@function token-breakpoint($val) {
  @return map-get(clear-keys-from-map($zaux-token-breakpoints), $val);
}

@function token-breakpoint-values() {
  @return token-breakpoint('values');
}

@function token-breakpoint-val($val) {
  @return map-get(token-breakpoint-values(), $val);
}


//////////////////////////////////////////////////
// TOKENS - ZINDEX
//////////////////////////////////////////////////

@function token-zindex($val) {
  @return map-get(clear-keys-from-map($zaux-token-zindex), $val);
}

@function token-zindex-values() {
  @return token-zindex('values');
}

@function token-zindex-val($val) {
  @return map-get(token-zindex-values(), $val);
}
