@charset "UTF-8";

$component: component-class("btn");

@mixin c-btn-size($px) {
  .#{$component}__inner {
    font-size: rem($px);
    height: 1em;
    border-radius: 0.5em;
  }
}

.#{$component}__inner {
  @include apply('text-center d-flex justify-content-center align-items-center');
}

[class*="#{$component}--"] {
  @include apply('d-inline-block');
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.#{$component} {

  &--size1 {
    @include c-btn-size(token-size-val(7));

    @include media-breakpoint-up(md) {
      @include c-btn-size(token-size-val(9));
    }
  }

  &--size2 {
    @include c-btn-size(token-size-val(6));

    @include media-breakpoint-up(md) {
      @include c-btn-size(token-size-val(7));
    }
  }

  &--style2 {
    .#{$component}__inner {
      border-style: solid;
      border-width: 2px;
    }
  }
}
