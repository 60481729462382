@charset "UTF-8";

////////////////////////////////////////////////////
// DEPENDENCIES
////////////////////////////////////////////////////

@import "../vendor/bootstrap.abstract";
@import "functions";


//////////////////////////////////////////////////
// TOKENS - BASE (those that not depend from vendors)
//////////////////////////////////////////////////

@import "../tokens/breakpoints";
@import "../tokens/containers";
@import "../tokens/colors";
@import "../tokens/defs";
@import "../tokens/fontsizes";
@import "../tokens/lineheights";
@import "../tokens/gutters";
@import "../tokens/styles";
@import "../tokens/sizes";
@import "../tokens/zindex";


//////////////////////////////////////////////////
// STATES
//////////////////////////////////////////////////

$zaux-system-classname-base: token-def("app-prefix");
$zaux-system-classname-loaded: "#{$zaux-system-classname-base}-loaded";

//////////////////////////////////////////////////
// EASINGS
// @see https://matthewlein.com/tools/ceaser
//////////////////////////////////////////////////
$zaux-easing-outcubic: cubic-bezier(0.215, 0.61, 0.355, 1); //easeOutCubic


//////////////////////////////////////////////////
// TRANSITIONS
//////////////////////////////////////////////////

$zaux-transition-1: 0.3s ease-in-out;


//////////////////////////////////////////////////
// FONTS
//////////////////////////////////////////////////

$zaux-font-size-root-px: 16px;
$zaux-font-1: 'proxima-nova', 'Helvetica Neue', Arial, sans-serif;
$zaux-font-2: 'Courier New', monospace;


//////////////////////////////////////////////////
// COMPONENTS SETTING
//////////////////////////////////////////////////

$zaux-component-prefix: token-def("component-prefix");
$zaux-helper-prefix: token-def("helper-prefix");

// Header
$zaux-header-mobile-h: token-size-val(12);
$zaux-header-desktop-w: 376px;


//////////////////////////////////////////////////
// COLORS
//////////////////////////////////////////////////

$zaux-custom-colors: (
);

$zaux-custom-bg-colors: (
);

@each $setName,
$setObj in token-colors() {

  @each $colorName,
  $colorVal in $setObj {
    @if str-index($setName, "core")==null {
      // RGB
      // $zaux-custom-colors: map-merge($zaux-custom-colors, (#{$setName}-#{$colorName}: rgba(var(--zx-#{$setName}-#{$colorName}-rgb), var(--bs-text-opacity))));
      // $zaux-custom-bg-colors: map-merge($zaux-custom-bg-colors, (#{$setName}-#{$colorName}: rgba(var(--zx-#{$setName}-#{$colorName}-rgb), var(--bs-bg-opacity))));

      // HSL 1
      // $zaux-custom-colors: map-merge($zaux-custom-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hsl), var(--bs-text-opacity)#{")"})));
      // $zaux-custom-bg-colors: map-merge($zaux-custom-bg-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hsl), var(--bs-bg-opacity)#{")"})));

      // HSLA 1
      // $zaux-custom-colors: map-merge($zaux-custom-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hsl), var(--bs-text-opacity)#{")"})));
      // $zaux-custom-bg-colors: map-merge($zaux-custom-bg-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hsl), var(--bs-bg-opacity)#{")"})));

      // HSL 2
      // $zaux-custom-colors: map-merge($zaux-custom-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hsl), var(--bs-text-opacity)#{")"})));
      // $zaux-custom-bg-colors: map-merge($zaux-custom-bg-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hsl), var(--bs-bg-opacity)#{")"})));

      // HSLA 2
      $zaux-custom-colors: map-merge($zaux-custom-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hue),
              var(--zx-#{$setName}-#{$colorName}-sat),
              var(--zx-#{$setName}-#{$colorName}-lum),
              var(--bs-text-opacity)#{")"})));

      $zaux-custom-bg-colors: map-merge($zaux-custom-bg-colors, (#{$setName}-#{$colorName}: #{"hsla("}(var(--zx-#{$setName}-#{$colorName}-hue),
              var(--zx-#{$setName}-#{$colorName}-sat),
              var(--zx-#{$setName}-#{$colorName}-lum),
              var(--bs-bg-opacity)#{")"})));
    }
  }
}

//////////////////////////////////////////////////
// BOOTSTRAP OVERRIDES VARIABLES
// @see https://github.com/twbs/bootstrap/blob/v5.3.0/scss/_variables.scss
// @see https://getbootstrap.com/docs/5.3/customize/color/
// @see https://getbootstrap.com/docs/5.3/customize/sass/#maps-and-loops
// @see https://getbootstrap.com/docs/5.3/customize/color/
//////////////////////////////////////////////////

//////////////////////////////////////////////////
// BOOTSTRAP: Flags
// @see https://getbootstrap.com/docs/5.3/customize/options/
//////////////////////////////////////////////////
$enable-dark-mode: false;
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: false;
$enable-grid-classes: true;
$enable-container-classes: true;
$enable-caret	: false;
$enable-button-pointers: true;
$enable-rfs: false;
$enable-validation-icons: false;
$enable-negative-margins: true;
$enable-deprecation-messages: false;
$enable-important-utilities: true;
$enable-smooth-scroll: false;


//////////////////////////////////////////////////
// BOOTSTRAP: Base variables
//////////////////////////////////////////////////

// Fonts
$font-size-root: null;
$font-size-base: rem($zaux-font-size-root-px);

$font-family-sans-serif: $zaux-font-1;
$font-family-monospace: $zaux-font-2;
// $font-weight-bolder: $font-weight-bold;
$font-weight-bolder: 800;

// Line-heights
$line-height-base: 1;
$line-height-sm: $line-height-base;
$line-height-lg: $line-height-base;

// Paragraphs
$paragraph-margin-bottom: 0;

// Headings
$headings-margin-bottom: 0;
$headings-font-family: inherit;
$headings-font-style: inherit;
$headings-font-weight: inherit;
$headings-line-height: inherit;
$headings-color: inherit;

// HR
$hr-margin-y: inherit;
$hr-color: inherit;
$hr-height: inherit;
$hr-opacity: inherit;

// Theme Colors
$primary: token-color("core", "primary");
$secondary: token-color("core", "secondary");
$success: token-color("core", "success");
$info: token-color("core", "info");
$warning: token-color("core", "warning");
$danger: token-color("core", "danger");

// Body
$body-color: token-color("set1", "blue1");
$body-bg: token-color("set1", "white1");

// Links
$link-color: token-color("set1", "cyan1");
$link-decoration: none;
$link-hover-color: shift-color($link-color, 20%);
$link-hover-decoration: null;

// Borders
$border-width: 1px;
$border-color: token-color("set1", "gray2");

// Modal
// $modal-backdrop-bg: token-color("set1", "white1");
$modal-backdrop-opacity: 0.75;
$modal-dialog-margin: 0;
$modal-inner-padding: 0;
$modal-content-border-radius: 0;
$modal-header-border-width: 0;
$modal-content-border-width: 0;
$modal-footer-border-width: 0;

// Forms
$input-bg: token-color("set1", "white1");
$input-border-width: 0;

$input-height: 60px;
$input-height-sm: $input-height;
$input-height-lg: $input-height-sm;

$input-height-inner: $input-height;
$input-height-inner-half: $input-height;
$input-height-inner-quarter: $input-height;

$input-border-radius: token-size-val(2);
$input-border-radius-sm: $input-border-radius;
$input-border-radius-lg: $input-border-radius;

// Zindex
$zindex-sticky: token-zindex-val('sticky');
$zindex-modal: token-zindex-val('modal');
$zindex-modal-backdrop: token-zindex-val('backdrop');

$zindex-levels: (
  "under": -1,
  "modal": token-zindex-val('modal'),
  "content": token-zindex-val('content'),
  "header": token-zindex-val('header'),
  "modal-backdrop": token-zindex-val('backdrop')
);

// Tables
$table-cell-padding-y: token-size-val(4);
$table-cell-padding-x: token-size-val(4);
$table-cell-padding-y-sm: token-size-val(4);
$table-cell-padding-x-sm: token-size-val(4);

// Adds the css vars below as default on the root element (removed from local variable in the generated utility css class, see _bootstrap.scss)
:root {
  --bs-text-opacity: 1;
  --bs-bg-opacity: 1;
}


//////////////////////////////////////////////////
// BOOTSTRAP: Display font sizes
//////////////////////////////////////////////////
$display-font-sizes: (
);

//////////////////////////////////////////////////
// BOOTSTRAP: Font size
//////////////////////////////////////////////////
$font-sizes: (
);

@each $key,
$value in token-fontsize-values() {
  $font-sizes: map-merge($font-sizes,
      ($key: if(type-of($key)=='number', rem(token-fontsize-val($key)), token-fontsize-val($key)),
      ));
}

//////////////////////////////////////////////////
// BOOTSTRAP: Spacers
//////////////////////////////////////////////////
$spacers: (
);

$spacer: rem(token-size('base-size'));

@each $key,
$value in token-size-values() {
  $spacers: map-merge($spacers,
      ($key: rem(token-size-val($key)),
      ));
}

// @see https://github.com/twbs/bootstrap/blob/413894d4691b5ebb1377fcf5ab8fd3bba49766a8/scss/_maps.scss#L172
$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

//////////////////////////////////////////////////
// BOOTSTRAP: Gutter
//////////////////////////////////////////////////
$gutters: (
);

@each $key,
$value in token-gutter-values() {
  $gutters: map-merge($gutters,
      ($key: rem(token-gutter-val($key)),
      ));
}

$grid-gutter-width: rem(token-gutter('base-size'));


//////////////////////////////////////////////////
// BOOTSTRAP: Grid breakpoints
//////////////////////////////////////////////////
$grid-breakpoints: (
);

@each $mq-prefix,
$value in token-breakpoint-values() {
  @if str-index($mq-prefix, "_")==null {
    $grid-breakpoints: map-merge($grid-breakpoints,
        ($mq-prefix: $value,
        ));
  }
}

//////////////////////////////////////////////////
// BOOTSTRAP: Containers
//////////////////////////////////////////////////
$container-max-widths: (
);

@each $mq-prefix,
$value in token-container-values() {
  @if str-index($mq-prefix, "_")==null {
    $container-max-widths: map-merge($container-max-widths,
        ($mq-prefix: $value,
        ));
  }
}

//////////////////////////////////////////////////
// BOOTSTRAP: Colors
//////////////////////////////////////////////////
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  // "light": $light,
  // "dark": $dark
);

// $theme-colors-text: (
// );

// $theme-colors-bg-subtle: (
// );

$theme-colors-border-subtle: (
);

$grays: (
);

$colors: (
);


//////////////////////////////////////////////////
// TOKENS - COMPOUNDS
//////////////////////////////////////////////////
@import "../tokens/_bs-utilities";

@if variable-exists('zaux-token-bs-utilities') {
  $utilities: map-get($zaux-token-bs-utilities, 'values');
}
