@charset "UTF-8";

$component: component-class("minicard1");

.#{$component} {

  p {
    line-height: 1.25;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
