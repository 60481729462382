@charset "UTF-8";

$component: component-class("btn2");

.#{$component} {
  border-radius: 40px 0 0 40px;
  overflow: hidden;
  z-index: 30;

  &__inner {
    height: 80px;
    padding: 16px;
  }

  &__label {
    display: inline-block;
  }

  .#{$component}__label {
    transition:
      /*block*/
      padding $zaux-transition-1
      /*endblock*/
    ;
  }

  &[class*="--active"] .#{$component}__label,
  &:active .#{$component}__label,
  &:focus .#{$component}__label,
  &:hover .#{$component}__label {
    padding: 0 1.5rem !important;
  }

  &--xs-layout1 {
    @include media-breakpoint-down(md) {
      transform: scale(0.5);
      transform-origin: top right;
    }
  }

}
