@charset "UTF-8";

$component: component-class("form");

.#{$component} {
  label {
    display: inherit;
  }

  .form-control,
  .form-select {
    @include apply('fs-5 fs-md-6 py-3 px-4 py-md-3 px-md-5');
  }

  .form-check-input {
    margin-top: 0.2em;
  }

  &__field--notice,
  &__field--radio,
  &__field--checkbox {
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.alert {
  border-width: 2px !important;
  border-radius: 4px;
  border-style: solid;
}
