/*!
  * Bootstrap v5.3.0 (https://getbootstrap.com/)
  * Copyright 2011-2023 The Bootstrap Authors
  * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
  */

//////////////////////////////////////////////////
// BOOTSTRAP: Layout & components
//////////////////////////////////////////////////
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms/labels";
@import "bootstrap/scss/forms/form-text";
@import "bootstrap/scss/forms/form-control";
@import "bootstrap/scss/forms/form-select";
@import "bootstrap/scss/forms/form-check";
@import "bootstrap/scss/forms/form-range";
@import "bootstrap/scss/forms/floating-labels";
@import "bootstrap/scss/forms/input-group";
@import "bootstrap/scss/forms/validation";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";


//////////////////////////////////////////////////
// BOOTSTRAP: Helpers
//////////////////////////////////////////////////
@import "bootstrap/scss/helpers/clearfix";
// @import "bootstrap/scss/helpers/color-bg";
// @import "bootstrap/scss/helpers/colored-links";
@import "bootstrap/scss/helpers/focus-ring";
// @import "bootstrap/scss/helpers/icon-link";
@import "bootstrap/scss/helpers/ratio";
@import "bootstrap/scss/helpers/position";
@import "bootstrap/scss/helpers/stacks";
@import "bootstrap/scss/helpers/visually-hidden";
@import "bootstrap/scss/helpers/stretched-link";
@import "bootstrap/scss/helpers/text-truncation";
@import "bootstrap/scss/helpers/vr";


//////////////////////////////////////////////////
// BOOTSTRAP: Utilities
//////////////////////////////////////////////////
@import "bootstrap/scss/utilities/api";
