@charset "UTF-8";

//////////////////////////////////////////////////
// ICON SET - icozaux1
//////////////////////////////////////////////////

$ico-set-name: 'icozaux1';

[class^="#{$ico-set-name}-"],
[class*=" #{$ico-set-name}-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.#{$ico-set-name} {
  // &-calendar_clock {
  //   transform: scale(5);
  // }

  // &-people {
  //   transform: scale(5);
  // }

  &-people {
    transform: scale(1.6);
  }

  // &-glass {
  //   // transform: scale(5);
  //   transform: scale(7.5);
  // }

  // &-book {
  //   transform: scale(5);
  // }

  &-book {
    transform: scale(1.35);
  }

  // &-arrow_big_right_pink {
  //   transform: scale(2.22);
  // }

  // &-small_arrow_right {
  //   transform: scale(14);
  // }

  // &-arrow_down {
  //   transform: scale(2.3);
  // }

  // &-question_mark_chat {
  //   transform: scale(1);
  // }

  // &-clock {
  //   transform: scale(6.5);
  // }

  // &-broken_pill {
  //   transform: scale(6.5);
  // }

  // &-calendar_clock {
  //   transform: scale(5.2);
  // }

  // &-stomac {
  //   transform: scale(5.2);
  // }

  // &-blister {
  //   transform: scale(5.2);
  // }

  // &-whole_pill {
  //   transform: scale(5.2);
  // }

  // &-food {
  //   transform: scale(5);
  // }

  // &-download {
  //   transform: scale(5.3);
  // }

  // &-info {
  //   transform: scale(6.4);
  // }

  // &-down {
  //   transform: scale(22);
  // }

  // &-cal {
  //   transform: scale(4.65);
  // }

  // &-cross1 {
  //   transform: scale(3.33);
  // }
}
