@charset "UTF-8";

$component: component-class("modal");

.#{$component} {
  svg.icon {
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  [data-bs-dismiss="modal"] {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    z-index: 12;
  }

  &--full1 {
    .modal-dialog {
      max-width: 80%;
    }
  }

  &.show {
    // display: flex !important;
  }
}
