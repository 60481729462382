@charset "UTF-8";

$component: component-class("img");

$zaux-img-loaded-class: "-is-loaded" !default;
$zaux-img-placeholder-bg-color: #333 !default;

.#{$component} {
  line-height: 0;
  display: block;

  img {
    opacity: 1;
    transition: opacity $zaux-transition-1;

    @at-root .zaux-trans-active & {
      transition: transform $zaux-transition-1, opacity $zaux-transition-1;
    }
  }

  &:not([class*="--lazy"])[class*="--fluid"] {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &--lazy {
    &[class*="--fluid"] {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    &:not([class*="#{$zaux-img-loaded-class}"]) {
      img {
        opacity: 0;
      }
    }
  }
}
