@charset "UTF-8";

////////////////////////////////////////////////////
// GENERAL
////////////////////////////////////////////////////

#zaux-content-inner {
  &:before {
    @include media-breakpoint-up(md) {
      display: block;
      content: " ";

      border-right: 16px solid token-color("set1", "pink1");
      background: token-color("set1", "gray3");
      position: fixed;
      top: 0;
      left: 0;

      width: $zaux-header-desktop-w;
      height: 100%;
      z-index: -2;
    }
  }
}


////////////////////////////////////////////////////
// RESPONSIVE UTILS
////////////////////////////////////////////////////

@include do-responsive(#{"."}ps, sidebar) {
  padding-left: $zaux-header-desktop-w;
}

// @include do-responsive(#{"."}zaux-fs, 160px) {
//   font-size: token-size('base-size') * 20 !important;
// }

.zaux-bg-xs-alpha {
  @include media-breakpoint-down(md) {
    background-color: transparent !important;

    &::before {
      display: none !important;
    }
  }
}


////////////////////////////////////////////////////
// HELPER / STYLES
////////////////////////////////////////////////////

// @include style('text2', 'p', ':not([class*="fs-"])'); // :not([class*="text-"])


////////////////////////////////////////////////////
// COLORS
////////////////////////////////////////////////////

.border-set1-pink1 {
  border-color: var(--zx-set1-pink1) !important;
}

////////////////////////////////////////////////////
// MISC
////////////////////////////////////////////////////

body {
  @include scrollbars(8px,
    rgba(token-color("set1", "black1"), 0.25),
    transparent,
    8px);
}

.zaux-text-sign1 {
  text-transform: uppercase;
  white-space: nowrap;

  sup {
    margin-left: -0.1em;
    font-size: 0.8em;
    line-height: 0;
  }
}

.zaux-col-line1 {
  position: relative;

  &:before {
    display: block;
    content: " ";

    position: absolute;
    opacity: 0.5;

    @include media-breakpoint-down(md) {
      bottom: 0;
      left: 5%;

      height: 1px;
      width: 90%;

      border-top: 1px solid var(--zx-set1-gray2);
    }

    @include media-breakpoint-up(md) {
      top: 0;
      right: 0;

      width: 1px;
      height: 100%;

      border-right: 1px solid var(--zx-set1-gray2);
    }
  }
}

////////////////////////////////////////////////////
// BOOTSTRAP OVERRIDDEN CSS CLASSES
////////////////////////////////////////////////////

.clearfix {
  &::after {
    min-height: 0.01px;
  }
}

@include do-responsive(#{".img"}, fluid) {
  @include img-fluid();
}

.zaux-table-border-bottom-0 {

  thead,
  tbody,
  tfoot {
    &:last-child {
      tr:last-child {

        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}

.zaux-table-border-style-1 {

  th,
  td {
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: inherit;

    &:not(:last-child) {
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: inherit;
    }
  }
}
