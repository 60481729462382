@charset "UTF-8";

$component: component-class("video");

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.#{$component} {

  video {
    background-color: var(--zx-set1-black2);
  }

  p,
  li,
  a {
    color: var(--zx-set1-white1);
  }
}
