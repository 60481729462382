@charset "UTF-8";

$component: component-class("header");

$zaux-header-mobile-h: token-size-val(12) !default;
$zaux-header-desktop-w: 100px !default;

.#{$component} {

  // Header tag: not fake
  &:not([aria-hidden="true"]) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    @include media-breakpoint-down(md) {
      z-index: 26;
    }
  }

  // Div tag: fake inside hero
  &[aria-hidden="true"] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 24;
    transform: translatey(var(--scroll-top, 0));
    will-change: transform;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    height: $zaux-header-mobile-h;
    background-color: white;

    .logo {
      max-width: 160px;
    }

    .btn-open-navmain {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      z-index: 1;
    }
  }

  @at-root .mobile-nav-open & {
    .nav1 {
      @include media-breakpoint-down(md) {
        display: block !important;

        background: #f4f4f4 !important;
        margin-bottom: 0 !important;
        margin-left: -0.75rem !important;
        margin-right: -0.75rem !important;
        padding: 20px !important;
        height: calc(100vh - 62px) !important;
        border-top: 14px solid white !important;

        li {
          margin-bottom: 14px !important;
          padding-bottom: 17px !important;
          border-bottom: 1px solid #ccc !important;

          br {
            display: none !important;
          }
        }
      }
    }
  }
}

#zaux-content-inner {
  @include media-breakpoint-down(md) {
    padding-top: $zaux-header-mobile-h;
  }
}

.nav1 {
  @include media-breakpoint-down(md) {
    ul ul {
      display: none;

      @at-root .mobile-nav-open & li:last-child {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        border-bottom: 0 !important;
      }
    }

    li.open {

      .c-icon {
        transform: rotate(-90deg);
      }

      >ul {
        display: block;
      }
    }
  }

  .c-icon {
    transform: rotate(90deg);
  }
}
