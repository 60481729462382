@charset "UTF-8";

$component: component-class("info1");

.#{$component} {
  // @include media-breakpoint-down(md) {
  //   .c-icon {
  //     transform: translateY(-8px);
  //   }
  // }

  @include media-breakpoint-up(md) {
    // min-height: 78px;
    min-height: 96px;
  }
}
