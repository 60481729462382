@charset "UTF-8";

$hamburger-layer-border-radius: 0;
$hamburger-types: (spin);
$hamburger-layer-width: 20px;
$hamburger-layer-height: 1px;

// VENDOR CONTENT
// ==================================================

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x : 15px !default;
$hamburger-padding-y : 15px !default;
$hamburger-layer-width : 40px !default;
$hamburger-layer-height : 4px !default;
$hamburger-layer-spacing : 6px !default;
$hamburger-layer-color : #000 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity : 0.7 !default;
$hamburger-active-layer-color : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter : false !default;
$hamburger-hover-filter : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spin,
  spin-r,
  spring,
  spring-r,
  stand,
  stand-r,
  squeeze,
  vortex,
  vortex-r) !default;

// Base Hamburger (We need this)
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity,
    filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter ==true {
      filter: $hamburger-hover-filter;
    }

    @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter ==true {
        filter: $hamburger-active-hover-filter;
      }

      @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  // margin-top: math.div($hamburger-layer-height, -2);
  margin-top: ($hamburger-layer-height /2) * -1;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}


// Hamburger types
// ==================================================
@import "hamburgers/_sass/hamburgers/types/3dx";
@import "hamburgers/_sass/hamburgers/types/3dx-r";
@import "hamburgers/_sass/hamburgers/types/3dy";
@import "hamburgers/_sass/hamburgers/types/3dy-r";
@import "hamburgers/_sass/hamburgers/types/3dxy";
@import "hamburgers/_sass/hamburgers/types/3dxy-r";
@import "hamburgers/_sass/hamburgers/types/arrow";
@import "hamburgers/_sass/hamburgers/types/arrow-r";
@import "hamburgers/_sass/hamburgers/types/arrowalt";
@import "hamburgers/_sass/hamburgers/types/arrowalt-r";
@import "hamburgers/_sass/hamburgers/types/arrowturn";
@import "hamburgers/_sass/hamburgers/types/arrowturn-r";
@import "hamburgers/_sass/hamburgers/types/boring";
@import "hamburgers/_sass/hamburgers/types/collapse";
@import "hamburgers/_sass/hamburgers/types/collapse-r";
// @import "hamburgers/_sass/hamburgers/types/elastic"; //doesn't work
// @import "hamburgers/_sass/hamburgers/types/elastic-r"; //doesn't work
@import "hamburgers/_sass/hamburgers/types/emphatic";
@import "hamburgers/_sass/hamburgers/types/emphatic-r";
@import "hamburgers/_sass/hamburgers/types/minus";
// @import "hamburgers/_sass/hamburgers/types/slider"; //doesn't work
// @import "hamburgers/_sass/hamburgers/types/slider-r"; //doesn't work
@import "hamburgers/_sass/hamburgers/types/spin";
@import "hamburgers/_sass/hamburgers/types/spin-r";
// @import "hamburgers/_sass/hamburgers/types/spring"; //doesn't work
// @import "hamburgers/_sass/hamburgers/types/spring-r"; //doesn't work
@import "hamburgers/_sass/hamburgers/types/stand";
@import "hamburgers/_sass/hamburgers/types/stand-r";
@import "hamburgers/_sass/hamburgers/types/squeeze";
@import "hamburgers/_sass/hamburgers/types/vortex";
@import "hamburgers/_sass/hamburgers/types/vortex-r";
