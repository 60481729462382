@charset "UTF-8";

$component: component-class("btn");

[class*='#{helper-class("theme")}'] .#{$component} {

  ////////////////////////////////////////////////////
  // Theme / Base
  ////////////////////////////////////////////////////

  text-decoration: none;

  &:focus,
  &:hover {
    &,
    * {
      text-decoration: inherit;
    }
  }

  &[disabled] {
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
    text-decoration: inherit;
  }

  ////////////////////////////////////////////////////
  // Theme / Colors
  ////////////////////////////////////////////////////

  &[class*="--color-"] .#{$component}__inner {
    transition:
      /*block*/
      color $zaux-transition-1,
      border-color $zaux-transition-1,
      background $zaux-transition-1
      /*endblock*/
    ;
  }

  ////////////////////////////////////////////////////
  // Theme / Colors / Pink1
  ////////////////////////////////////////////////////

  &[class*="--color-pink1"] {
    --btn-hover-hue-factor: 0deg;
    --btn-hover-sat-factor: 0%;
    --btn-hover-lum-factor: -15%;
  }

  &[class*="--color-pink1"] .#{$component}__inner {
    color: var(--zx-set1-white1);
    border-color: var(--zx-set1-pink1);
    background: var(--zx-set1-pink1);
  }

  &[class*="--color-pink1"][class*="--active"] .#{$component}__inner,
  &[class*="--color-pink1"]:active .#{$component}__inner,
  &[class*="--color-pink1"]:focus .#{$component}__inner,
  &[class*="--color-pink1"]:hover .#{$component}__inner {
    color: var(--zx-set1-white1);

    border-color: hsla(
        /*block*/
        calc(var(--zx-set1-pink1-hue) + var(--btn-hover-hue-factor)),
        calc(var(--zx-set1-pink1-sat) + var(--btn-hover-sat-factor)),
        calc(var(--zx-set1-pink1-lum) + var(--btn-hover-lum-factor)),
        1
        /*endblock*/
      );

    background: hsla(
        /*block*/
        calc(var(--zx-set1-pink1-hue) + var(--btn-hover-hue-factor)),
        calc(var(--zx-set1-pink1-sat) + var(--btn-hover-sat-factor)),
        calc(var(--zx-set1-pink1-lum) + var(--btn-hover-lum-factor)),
        1
        /*endblock*/
      );
  }

  ////////////////////////////////////////////////////
  // Theme / Colors / Violet1
  ////////////////////////////////////////////////////

  &[class*="--color-violet1"] {
    --btn-hover-hue-factor: 0deg;
    --btn-hover-sat-factor: 0%;
    --btn-hover-lum-factor: 5%;
  }

  &[class*="--color-violet1"] .#{$component}__inner {
    color: var(--zx-set1-violet1);
    border-color: var(--zx-set1-violet1);
    background: var(--zx-set1-white1);
  }

  &[class*="--color-violet1"][class*="--active"] .#{$component}__inner,
  &[class*="--color-violet1"]:active .#{$component}__inner,
  &[class*="--color-violet1"]:focus .#{$component}__inner,
  &[class*="--color-violet1"]:hover .#{$component}__inner {
    color: var(--zx-set1-white1);

    border-color: hsla(
        /*block*/
        calc(var(--zx-set1-violet1-hue) + var(--btn-hover-hue-factor)),
        calc(var(--zx-set1-violet1-sat) + var(--btn-hover-sat-factor)),
        calc(var(--zx-set1-violet1-lum) + var(--btn-hover-lum-factor)),
        1
        /*endblock*/
      );

    background: hsla(
        /*block*/
        calc(var(--zx-set1-violet1-hue) + var(--btn-hover-hue-factor)),
        calc(var(--zx-set1-violet1-sat) + var(--btn-hover-sat-factor)),
        calc(var(--zx-set1-violet1-lum) + var(--btn-hover-lum-factor)),
        1
        /*endblock*/
      );
  }

}
