@charset "UTF-8";

$component: component-class("btn2");

.#{$component} {

  ////////////////////////////////////////////////////
  // Theme / Base
  ////////////////////////////////////////////////////

  text-decoration: none;

  &:focus,
  &:hover {

    &,
    * {
      text-decoration: inherit;
    }
  }

  &[disabled] {
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
    text-decoration: inherit;
  }

  ////////////////////////////////////////////////////
  // Theme / Colors
  ////////////////////////////////////////////////////

  &[class*="--color-"] .#{$component}__inner,
  &[class*="--grad-"] .#{$component}__inner {
    transition:
      /*block*/
      color $zaux-transition-1,
      border-color $zaux-transition-1,
      background $zaux-transition-1
      /*endblock*/
    ;
  }

  ////////////////////////////////////////////////////
  // Theme / Colors / Violet1
  ////////////////////////////////////////////////////

  &[class*="--grad-pink1violet1"] .#{$component}__inner {
    color: var(--zx-set1-white1);
    // border-color: var(--zx-set1-violet1);

    background: rgba(token-color("set1", "pink1"), 1);
    background: linear-gradient(135deg, rgba(token-color("set1", "pink1"), 1) 50%, rgba(token-color("set1", "violet1"), 1) 100%);
  }

  &[class*="--grad-pink1violet1"][class*="--active"] .#{$component}__inner,
  &[class*="--grad-pink1violet1"]:active .#{$component}__inner,
  &[class*="--grad-pink1violet1"]:focus .#{$component}__inner,
  &[class*="--grad-pink1violet1"]:hover .#{$component}__inner {
    color: var(--zx-set1-white1);
    // border-color: yellow
  }
}
