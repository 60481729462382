// From: /bootstrap/scss/mixins/_utilities.scss
// Utility generator
// Used to generate utilities & print utilities
@mixin generate-utility-placeholders($utility, $infix: "", $is-rfs-media-query: false) {
  $values: map-get($utility, values);

  // If the values are a list or string, convert it into a map
  @if type-of($values)=="string" or type-of(nth($values, 1)) !="list" {
    $values: zip($values, $values);
  }

  @each $key,
  $value in $values {
    $properties: map-get($utility, property);

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if type-of($properties)=="string" {
      $properties: append((), $properties);
    }

    // Use custom class if present
    $property-class: if(map-has-key($utility, class), map-get($utility, class), nth($properties, 1));
    $property-class: if($property-class ==null, "", $property-class);

    // Use custom CSS variable name if present, otherwise default to `class`
    $css-variable-name: if(map-has-key($utility, css-variable-name), map-get($utility, css-variable-name), map-get($utility, class));

    // State params to generate pseudo-classes
    $state: if(map-has-key($utility, state), map-get($utility, state), ());

    $infix: if($property-class =="" and str-slice($infix, 1, 1)=="-", str-slice($infix, 2), $infix);

    // Don't prefix if value key is null (e.g. with shadow class)
    $property-class-modifier: if($key, if($property-class =="" and $infix =="", "", "-") + $key, "");

    @if map-get($utility, rfs) {

      // Inside the media query
      @if $is-rfs-media-query {
        $val: rfs-value($value);

        // Do not render anything if fluid and non fluid values are the same
        $value: if($val ==rfs-fluid-value($value), null, $val);
      }

      @else {
        $value: rfs-fluid-value($value);
      }
    }

    // $is-css-var: map-get($utility, css-var);
    $is-local-vars: map-get($utility, local-vars);
    $is-rtl: map-get($utility, rtl);

    @if $value !=null {
      // @if $is-rtl == false {
      //   /* rtl:begin:remove */
      // }

      // @if $is-css-var {
      //   .#{$property-class + $infix + $property-class-modifier} {
      //     --#{$prefix}#{$css-variable-name}: #{$value};
      //   }

      //   @each $pseudo in $state {
      //     .#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
      //       --#{$prefix}#{$css-variable-name}: #{$value};
      //     }
      //   }
      // }

      // @else {
      %#{$property-class + $infix + $property-class-modifier} {
        @each $property in $properties {
          @if $is-local-vars {

            @each $local-var,
            $variable in $is-local-vars {
              --#{$prefix}#{$local-var}: #{$variable};
            }
          }

          // #{$property}: $value if($enable-important-utilities-in-sass-placeholders, !important, null);
          #{$property}: $value;
        }
      }

      @each $pseudo in $state {
        %#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
          @each $property in $properties {
            @if $is-local-vars {

              @each $local-var,
              $variable in $is-local-vars {
                --#{$prefix}#{$local-var}: #{$variable};
              }
            }

            // #{$property}: $value if($enable-important-utilities-in-sass-placeholders, !important, null);
            #{$property}: $value;
          }
        }
      }

      // }

      // @if $is-rtl ==false {
      //   /* rtl:end:remove */
      // }
    }
  }
}


// From: /bootstrap/scss/utilities/_api.scss
// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $key,
    $utility in $utilities {

      @if type-of($utility)=="map" and (map-get($utility, responsive) or $infix =="") {
        @include generate-utility-placeholders($utility, $infix);
      }
    }
  }
}

// https://stackoverflow.com/questions/65518846/sass-function-to-split-strings-is-only-splitting-on-first-occurrence
@function strSplit($str, $substr) {
  $nList: (
  );

@while str-index("#{$str}", "#{$substr}") !=null {
  @if (str-index("#{$str}", "#{$substr}") > 1) {
    $nList: append($nList,
        str-slice("#{$str}", 1, str-index("#{$str}", "#{$substr}") - 1));
  }

  $str: str-slice("#{$str}",
      str-index("#{$str}", "#{$substr}") + 1,
      str-length("#{$str}"));
}

@if (str-slice("#{$str}", 1, str-length("#{$str}")) !="") {
  $nList: append($nList, str-slice("#{$str}", 1, str-length("#{$str}")));
}

@return $nList;
}

@mixin apply($str) {
  $strSplitMap: strSplit($str, " ");

  @each $v in $strSplitMap {
    @extend #{'%'}#{$v};
  }
}
