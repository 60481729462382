@charset "UTF-8";

$component: component-class("btn-list");

.#{$component} {
  @include apply('fs-0');
  @include apply('mx-n5 mx-md-0');
  @include apply('px-5 px-md-0');

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-down(md) {
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
}
