$zaux-token-sizes: (
  generateSassMap: true,
  base-size: 8px,
  values: (
    0: 0px,
    1: 4px,
    2: 8px,
    3: 12px,
    4: 16px,
    5: 24px,
    6: 32px,
    7: 40px,
    8: 48px,
    9: 56px,
    10: 64px,
    11: 72px,
    12: 80px,
    13: 88px,
    14: 96px,
    15: 104px,
    16: 112px,
    17: 120px
  )
);
