@charset "UTF-8";

$component: component-class("icon");

.#{$component} {
  display: inline-block;

  &,
  &[class*="-fs"] {
    line-height: 0 !important;
  }

  svg {
    pointer-events: none;
  }
}
