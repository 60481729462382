@charset "UTF-8";

$component: component-class("header");

.#{$component} {
  @include media-breakpoint-down(md) {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }
}

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

[class*="#{$component}"].#{helper-class("theme")}--light1 {

  a {
    transition:
      /*block*/
      color $zaux-transition-1
      /*endblock*/
    ;
  }

  .nav1 {

    li {
      font-weight: normal;
    }

    li,
    a {
      color: var(--zx-set1-black1);
    }

    a:hover {
      color: var(--zx-set1-pink1);
    }

    li {

      &.active,
      &.current-menu-item {
        font-weight: bold;
        > a {
          color: var(--zx-set1-pink1);

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

  }

  .nav2 {

    li,
    a {
      color: var(--zx-set1-cyan1);
      text-decoration: underline;
    }

    a {
      &:hover {
        color: var(--zx-set1-pink1);
        text-decoration: underline;
      }
    }

  }

}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

[class*="#{$component}"].#{helper-class("theme")}--dark1 {

  a {
    transition:
      /*block*/
      color $zaux-transition-1
      /*endblock*/
    ;
  }

  .nav1 {

    li {
      font-weight: bold;
    }

    li,
    a {
      color: var(--zx-set1-white1);
    }

    li {

      &.active,
      &.current-menu-item {
        a {
          color: var(--zx-set1-pink1);

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

  }

  .nav2 {

    li,
    a {
      color: var(--zx-set1-cyan1);
      text-decoration: underline;
    }

    a {
      &:hover {
        color: var(--zx-set1-pink1);
        text-decoration: underline;
      }
    }

  }

}
