@charset "UTF-8";

$component: component-class("video");

.#{$component} {
  &:not(.is-playing) {
    cursor: pointer;
  }

  &__details {
    pointer-events: none;
    top: initial;
    width: initial;
    height: initial;
  }
}
