@charset "UTF-8";

$component: component-class("typo");

////////////////////////////////////////////////////
// Typography Block: Style1
////////////////////////////////////////////////////

$style-name: "style1";

.#{$component}--#{$style-name} {

  p:not([class*="fs-"]),
  li:not([class*="fs-"]) {
    @include apply(token-style('text2'));
  }

  strong {
    font-weight: $font-weight-bolder;
  }
}


////////////////////////////////////////////////////
// Typography Block: Style2
////////////////////////////////////////////////////

$style-name: "style2";

.#{$component}--#{$style-name} {

  p:not([class*="fs-"]),
  li:not([class*="fs-"]) {
    @include apply(token-style('minitext2'));
  }

  strong {
    font-weight: $font-weight-bolder;
  }

  ul,
  ol {
    padding-left: token-size-val(5);
  }

  p:not(:last-child),
  li:not(:last-child),
  ul:not(:last-child),
  ol:not(:last-child) {
    margin-bottom: token-size-val(1);
  }

  ul ul,
  ol ol {
    padding-top: token-size-val(5);
  }

  ul li {
    list-style-type: none;

    &:before {
      content: "-";
      float: left;
      line-height: 1;

      margin: -0.25em 0 0 -0.55em;
      font-size: 1.7em;
      font-weight: 300;
    }
  }

}
