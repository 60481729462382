@charset "UTF-8";

:root {
  @each $setName,
  $setObj in token-colors() {

    @each $colorName,
    $colorVal in $setObj {
      // RGB HEX
      --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}: #{$colorVal};
      // --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}-rgb: #{to-rgb($colorVal)};

      // HSL 1
      // --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}-hsl: #{hue($colorVal), saturation($colorVal), lightness($colorVal)};

      // HSLA 1
      // --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}-hsla: #{"hsla("}#{hue($colorVal), saturation($colorVal), lightness($colorVal)}#{", 1)"};

      // HSL 2
      // --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}-hsl: #{"hsl("}#{hue($colorVal), saturation($colorVal), lightness($colorVal)}#{")"};

      // HSL 3
      --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}-hue: #{hue($colorVal)};
      --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}-sat: #{saturation($colorVal)};
      --#{token-def('css-var-prefix')}-#{$setName}-#{$colorName}-lum: #{lightness($colorVal)};
    }
  }
}

body {
  // Improving Font Rendering With CSS
  // @see https://betterprogramming.pub/improving-font-rendering-with-css-3383fc358cbc
  @include text-antialiased;
}

ul,
ol,
li,
dl,
dt,
dd,
address,
figure {
  padding: 0;
  margin: 0;
}

button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}
