@charset "UTF-8";

$component: component-class("slider");

.#{$component} {

  --swiper-theme-color: var(--zx-set1-pink1);
  --swiper-pagination-bullet-inactive-color: var(--zx-set1-gray1);
  --swiper-pagination-bullet-horizontal-gap: #{token-size-val(2)};
  --swiper-pagination-bullet-vertical-gap: 0;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;

  &__instance {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
  }

  &--nav-pos1 {
    [class*="__nav-btn"] {
      font-size: 16px;

      &::after {
        content: none;
      }

      &[class*="-prev"] {
        left: -#{token-size-val(7)};
        transform: scaleX(-1);
      }

      &[class*="-next"] {
        right: -#{token-size-val(7)};
      }

      top: 29%;

      @include media-breakpoint-up(md) {
        top: 39%;
      }
    }
  }

  &[v-cloak],
  &[data-v-cloak] {
    display: none;
  }
}
