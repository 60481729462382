$zaux-token-bs-utilities: (
  generateSassMap: true,
  values: (
    align: (
      property: vertical-align,
      class: align,
      values: baseline top middle bottom text-bottom text-top
    ),
    float: (
      responsive: true,
      property: float,
      values: (
        start: left,
        end: right,
        none: none
      )
    ),
    object-fit: (
      responsive: true,
      property: object-fit,
      values: (
        contain: contain,
        cover: cover,
        fill: fill,
        scale: scale-down,
        none: none
      )
    ),
    opacity: (
      property: opacity,
      values: (
        0: 0,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1
      )
    ),
    overflow: (
      property: overflow,
      values: auto hidden visible scroll
    ),
    overflow-x: (
      property: overflow-x,
      values: auto hidden visible scroll
    ),
    overflow-y: (
      property: overflow-y,
      values: auto hidden visible scroll
    ),
    display: (
      responsive: true,
      print: true,
      property: display,
      class: d,
      values: inline inline-block block grid inline-grid table table-row table-cell flex inline-flex none
    ),
    shadow: (
      property: box-shadow,
      class: shadow,
      values: (
        null: $box-shadow,
        sm: $box-shadow-sm,
        lg: $box-shadow-lg,
        none: none
      )
    ),
    focus-ring: (
      css-var: true,
      css-variable-name: focus-ring-color,
      class: focus-ring,
      values: map-loop($theme-colors-rgb, rgba-css-var, "$key", "focus-ring")
    ),
    position: (
      responsive: true,
      property: position,
      values: static relative absolute fixed sticky
    ),
    top: (
      property: top,
      values: $position-values
    ),
    bottom: (
      property: bottom,
      values: $position-values
    ),
    start: (
      property: left,
      class: start,
      values: $position-values
    ),
    end: (
      property: right,
      class: end,
      values: $position-values
    ),
    translate-middle: (
      property: transform,
      class: translate-middle,
      values: (
        null: translate(-50%, -50%),
        x: translateX(-50%),
        y: translateY(-50%)
      )
    ),
    scale: (
      property: transform,
      class: scale,
      values: (
        xflip: scaleX(-1),
        yflip: scaleY(-1)
      )
    ),
    border: (
      property: border,
      values: (
        0: 0,
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color)
      )
    ),
    border-top: (
      property: border-top,
      values: (
        0: 0,
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color)
      )
    ),
    border-end: (
      property: border-right,
      class: border-end,
      values: (
        0: 0,
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color)
      )
    ),
    border-bottom: (
      property: border-bottom,
      values: (
        0: 0,
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color)
      )
    ),
    border-start: (
      property: border-left,
      class: border-start,
      values: (
        0: 0,
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color)
      )
    ),
    border-color: (
      property: border-color,
      class: border,
      local-vars: (
        border-opacity: 1
      ),
      values: $utilities-border-colors
    ),
    subtle-border-color: (
      property: border-color,
      class: border,
      values: $utilities-border-subtle
    ),
    border-width: (
      property: border-width,
      class: border,
      values: $border-widths
    ),
    border-opacity: (
      css-var: true,
      class: border-opacity,
      values: (
        10: 0.1,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1
      )
    ),
    width: (
      property: width,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
    max-width: (
      responsive: false,
      property: max-width,
      class: mw,
      values: (
        50: 50%,
        100: 100%,
        sm: 768px,
        md: 992px,
        lg: 1440px
      )
    ),
    viewport-width: (
      property: width,
      class: vw,
      values: (
        100: 100vw
      )
    ),
    min-viewport-width: (
      property: min-width,
      class: min-vw,
      values: (
        100: 100vw
      )
    ),
    height: (
      property: height,
      class: h,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        i10: 64px,
        auto: auto
      )
    ),
    max-height: (
      property: max-height,
      class: mh,
      values: (
        100: 100%,
        i12: 80px
      )
    ),
    viewport-height: (
      responsive: true,
      property: height,
      class: vh,
      values: (
        100: 100vh
      )
    ),
    min-height: (
      property: min-height,
      class: mih,
      values: (
        i10: 64px
      )
    ),
    min-viewport-height: (
      property: min-height,
      class: min-vh,
      values: (
        100: 100vh
      )
    ),
    flex: (
      responsive: true,
      property: flex,
      values: (
        fill: 1 1 auto
      )
    ),
    flex-direction: (
      responsive: true,
      property: flex-direction,
      class: flex,
      values: row column row-reverse column-reverse
    ),
    flex-grow: (
      responsive: true,
      property: flex-grow,
      class: flex,
      values: (
        grow-0: 0,
        grow-1: 1
      )
    ),
    flex-shrink: (
      responsive: true,
      property: flex-shrink,
      class: flex,
      values: (
        shrink-0: 0,
        shrink-1: 1
      )
    ),
    flex-wrap: (
      responsive: true,
      property: flex-wrap,
      class: flex,
      values: wrap nowrap wrap-reverse
    ),
    justify-content: (
      responsive: true,
      property: justify-content,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        between: space-between,
        around: space-around,
        evenly: space-evenly
      )
    ),
    align-items: (
      responsive: true,
      property: align-items,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch
      )
    ),
    align-content: (
      responsive: true,
      property: align-content,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        between: space-between,
        around: space-around,
        stretch: stretch
      )
    ),
    align-self: (
      responsive: true,
      property: align-self,
      values: (
        auto: auto,
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch
      )
    ),
    order: (
      responsive: true,
      property: order,
      values: (
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        first: -1,
        last: 6
      )
    ),
    margin: (
      responsive: true,
      property: margin,
      class: m,
      values: map-merge($spacers, (auto: auto))
    ),
    margin-x: (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: map-merge($spacers, (auto: auto))
    ),
    margin-y: (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: map-merge($spacers, (auto: auto))
    ),
    margin-top: (
      responsive: true,
      property: margin-top,
      class: mt,
      values: map-merge($spacers, (auto: auto))
    ),
    margin-end: (
      responsive: true,
      property: margin-right,
      class: me,
      values: map-merge($spacers, (auto: auto))
    ),
    margin-bottom: (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: map-merge($spacers, (auto: auto))
    ),
    margin-start: (
      responsive: true,
      property: margin-left,
      class: ms,
      values: map-merge($spacers, (auto: auto))
    ),
    negative-margin: (
      responsive: true,
      property: margin,
      class: m,
      values: $negative-spacers
    ),
    negative-margin-x: (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: $negative-spacers
    ),
    negative-margin-y: (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: $negative-spacers
    ),
    negative-margin-top: (
      responsive: true,
      property: margin-top,
      class: mt,
      values: $negative-spacers
    ),
    negative-margin-end: (
      responsive: true,
      property: margin-right,
      class: me,
      values: $negative-spacers
    ),
    negative-margin-bottom: (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: $negative-spacers
    ),
    negative-margin-start: (
      responsive: true,
      property: margin-left,
      class: ms,
      values: $negative-spacers
    ),
    padding: (
      responsive: true,
      property: padding,
      class: p,
      values: $spacers
    ),
    padding-x: (
      responsive: true,
      property: padding-right padding-left,
      class: px,
      values: $spacers
    ),
    padding-y: (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $spacers
    ),
    padding-top: (
      responsive: true,
      property: padding-top,
      class: pt,
      values: $spacers
    ),
    padding-end: (
      responsive: true,
      property: padding-right,
      class: pe,
      values: $spacers
    ),
    padding-bottom: (
      responsive: true,
      property: padding-bottom,
      class: pb,
      values: $spacers
    ),
    padding-start: (
      responsive: true,
      property: padding-left,
      class: ps,
      values: $spacers
    ),
    gap: (
      responsive: true,
      property: gap,
      class: gap,
      values: $spacers
    ),
    row-gap: (
      responsive: true,
      property: row-gap,
      class: row-gap,
      values: $spacers
    ),
    column-gap: (
      responsive: true,
      property: column-gap,
      class: column-gap,
      values: $spacers
    ),
    font-family: (
      property: font-family,
      class: font,
      values: (monospace: var(--#{$prefix}font-monospace))
    ),
    font-size: (
      responsive: true,
      rfs: $enable-rfs,
      property: font-size,
      class: fs,
      values: $font-sizes
    ),
    font-style: (
      property: font-style,
      class: fst,
      values: italic normal
    ),
    font-weight: (
      property: font-weight,
      class: fw,
      values: (
        lighter: $font-weight-lighter,
        light: $font-weight-light,
        normal: $font-weight-normal,
        medium: $font-weight-medium,
        semibold: $font-weight-semibold,
        bold: $font-weight-bold,
        bolder: $font-weight-bolder
      )
    ),
    line-height: (
      property: line-height,
      class: lh,
      values: (
        1: 0.6,
        2: 0.8,
        3: 1,
        4: 1.2,
        5: 1.4,
        6: 1.6
      )
    ),
    text-align: (
      responsive: true,
      property: text-align,
      class: text,
      values: (
        start: left,
        end: right,
        center: center
      )
    ),
    text-decoration: (
      property: text-decoration,
      values: none underline line-through
    ),
    text-transform: (
      property: text-transform,
      class: text,
      values: lowercase uppercase capitalize
    ),
    white-space: (
      property: white-space,
      class: text,
      values: (
        wrap: normal,
        nowrap: nowrap
      )
    ),
    word-wrap: (
      property: word-wrap word-break,
      class: text,
      values: (
        break: break-word
      ),
      rtl: false
    ),
    color: (
      property: color,
      class: text,
      values: map-merge-multiple($utilities-text-colors, $zaux-custom-colors, ("reset": inherit))
    ),
    text-opacity: (
      css-var: true,
      class: text-opacity,
      values: (
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1
      )
    ),
    text-color: (
      property: color,
      class: text,
      values: $utilities-text-emphasis-colors
    ),
    link-opacity: (
      css-var: true,
      class: link-opacity,
      state: hover,
      values: (
        10: 0.1,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1
      )
    ),
    link-offset: (
      property: text-underline-offset,
      class: link-offset,
      state: hover,
      values: (
        1: .125em,
        2: .25em,
        3: .375em
      )
    ),
    link-underline: (
      property: text-decoration-color,
      class: link-underline,
      local-vars: (
        link-underline-opacity: 1
      ),
      values: map-merge( $utilities-links-underline, ( null: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-underline-opacity, 1)), ) )
    ),
    link-underline-opacity: (
      css-var: true,
      class: link-underline-opacity,
      state: hover,
      values: (
        0: 0,
        10: 0.1,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1
      )
    ),
    background-color: (
      property: background-color,
      class: bg,
      values: map-merge-multiple($utilities-bg-colors, $zaux-custom-bg-colors, ("transparent": transparent))
    ),
    bg-opacity: (
      css-var: true,
      class: bg-opacity,
      values: (
        10: 0.1,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1
      )
    ),
    subtle-background-color: (
      property: background-color,
      class: bg,
      values: $utilities-bg-subtle
    ),
    gradient: (
      property: background-image,
      class: bg,
      values: (gradient: var(--#{$prefix}gradient))
    ),
    user-select: (
      property: user-select,
      values: all auto none
    ),
    pointer-events: (
      property: pointer-events,
      class: pe,
      values: none auto
    ),
    rounded: (
      responsive: true,
      property: border-radius,
      class: rounded,
      values: (
        0: 0,
        1: var(--#{$prefix}border-radius-sm),
        2: var(--#{$prefix}border-radius),
        3: var(--#{$prefix}border-radius-lg),
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-xxl),
        null: var(--#{$prefix}border-radius),
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    ),
    rounded-top: (
      property: border-top-left-radius border-top-right-radius,
      class: rounded-top,
      values: (
        0: 0,
        1: var(--#{$prefix}border-radius-sm),
        2: var(--#{$prefix}border-radius),
        3: var(--#{$prefix}border-radius-lg),
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-xxl),
        null: var(--#{$prefix}border-radius),
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    ),
    rounded-end: (
      property: border-top-right-radius border-bottom-right-radius,
      class: rounded-end,
      values: (
        0: 0,
        1: var(--#{$prefix}border-radius-sm),
        2: var(--#{$prefix}border-radius),
        3: var(--#{$prefix}border-radius-lg),
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-xxl),
        null: var(--#{$prefix}border-radius),
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    ),
    rounded-bottom: (
      property: border-bottom-right-radius border-bottom-left-radius,
      class: rounded-bottom,
      values: (
        0: 0,
        1: var(--#{$prefix}border-radius-sm),
        2: var(--#{$prefix}border-radius),
        3: var(--#{$prefix}border-radius-lg),
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-xxl),
        null: var(--#{$prefix}border-radius),
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    ),
    rounded-start: (
      property: border-bottom-left-radius border-top-left-radius,
      class: rounded-start,
      values: (
        0: 0,
        1: var(--#{$prefix}border-radius-sm),
        2: var(--#{$prefix}border-radius),
        3: var(--#{$prefix}border-radius-lg),
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-xxl),
        null: var(--#{$prefix}border-radius),
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    ),
    visibility: (
      property: visibility,
      class: null,
      values: (
        visible: visible,
        invisible: hidden
      )
    ),
    z-index: (
      property: z-index,
      class: z,
      values: $zindex-levels
    )
  )
);
