@charset "UTF-8";

$helper: helper-class("theme");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$helper}--light1 {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--zx-set1-pink1)
  }

  p,
  li {
    color: var(--zx-set1-blue1)
  }

  ul li {
    &:before {
      color: var(--zx-set1-blue1)
    }
  }

}

.#{$helper}--light2 {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--zx-set1-pink1)
  }

  p,
  li {
    color: var(--zx-set1-violet1)
  }

  ul li {
    &:before {
      color: var(--zx-set1-cyan1)
    }
  }

}

.#{$helper}--light3 {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--zx-set1-blue1)
  }

  a {

    &,
    &:visited,
    &:hover {
      color: var(--zx-set1-blue1);
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  ul li {
    &:before {
      color: var(--zx-set1-cyan1)
    }
  }

}


////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.#{$helper}--dark1 {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--zx-set1-white1)
  }

  a {

    &:link,
    &:visited,
    &:hover {
      color: var(--zx-set1-white1)
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
