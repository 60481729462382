$zaux-token-zindex: (
  generateSassMap: true,
  values: (
    header: 100,
    modal: 80,
    backdrop: 75,
    sticky: 70,
    content: 20,
    overlay: 15,
    under: -1
  )
);
