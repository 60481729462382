$zaux-token-containers: (
  generateSassMap: true,
  values: (
    sm: 736px,
    md: 960px,
    lg: 1408px
  ),
  _default_bs: (
    ref: url('https://github.com/twbs/bootstrap/blob/v5.3.0/scss/_variables.scss#L503'),
    values: (
      sm: 540px,
      md: 720px,
      lg: 960px,
      xl: 1140px,
      xxl: 1320px
    )
  )
);
