@charset "UTF-8";

$component: component-class("info2");

.#{$component} {
  position: relative;

  @include media-breakpoint-down(md) {
    padding-left: 35% !important;
  }

  .c-img {
    @include media-breakpoint-down(md) {
      position: absolute;
      top: 50%;
      left: 10px;

      transform: translateY(-50%);
      width: 30%;
      padding: 0 3%;
    }
  }
}
