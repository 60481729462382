$zaux-token-colors: (
  generateSassMap: true,
  values: (
    core: (
      primary: #0d6efd,
      secondary: #6c757d,
      success: #198754,
      info: #0dcaf0,
      warning: #ffc107,
      danger: #dc3545
    ),
    app: (
      theme: #EE2A7A
    ),
    set1: (
      pink1: #EE2A7A,
      cyan1: #65cada,
      blue1: #243746,
      violet1: #421e5e,
      gray1: #f6f6f6,
      gray2: #d1d2d4,
      gray3: #fafafb,
      black1: #000000,
      black2: #222222,
      black3: #333333,
      white1: #FFFFFF
    )
  )
);
