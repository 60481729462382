@charset "UTF-8";

$component: component-class("video-gallery");

.#{$component} {

  .c-slider {
    article {
      cursor: pointer;
    }
  }
}
